import axios from '@/api/index'
// 新售后订单列表
export const Orderlist = data => {
    return axios.request({
        url: `/sale_ticket/admin/order/list`,
        method: 'post',
        data
    })
}

// 新售后订单详情  
export const Orderdetail = data => {
    return axios.request({
        url: `/sale_ticket/order/info`,
        method: 'post',
        data
    })
}

// 新售后工单详情 
export const Workdetail = data => {
    return axios.request({
        url: `/sale_ticket/work/order/info`,
        method: 'post',
        data
    })
}

// 新售后用料单详情 
export const Orderinfo = data => {
    return axios.request({
        url: `/sale_ticket/matter/order/info`,
        method: 'post',
        data
    })
}

// 业务公司列表 
export const Options = params => {
    return axios.request({
      url: '/sale/reimburse/options',
      method: 'get',
      params
    })
  }

// 新后台售后维修工单列表 
export const Workorderlist = data => {
    return axios.request({
        url: `/sale_ticket/admin/work/order/list`,
        method: 'post',
        data
    })
}

// 下载
export const Exports = data => {
    return axios.request({
        url: `/sale_ticket/admin/work/order/export`,
        method: 'post',
        data,
        responseType: 'blob'
    })
}

// 新售后用料单列表 
export const Matterorder = data => {
    return axios.request({
        url: `/sale_ticket/admin/matter/order/list`,
        method: 'post',
        data,
    })
}