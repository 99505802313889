<template>
  <div class="box">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>售后订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>售后订单列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容开始 -->
    <el-card class="box-card">
      <div class="lilst">
        <div class="tis">
          <div class="titles">订单编号</div>
          <el-input style="width: 300px" placeholder="请输入订单编号" v-model="ticketno" class="input-with-select" clearable @clear="handleEmptyticketno">
            <el-button slot="append" icon="el-icon-search" @click="ticketnofun()"></el-button>
          </el-input>
        </div>

        <div class="tis">
          <div class="titles">日期筛选</div>
          <el-date-picker
            :picker-options="pickerOptions"
            @change="gettime"
            v-model="timeFrom"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>

        <div class="tis">
          <div class="titles">业务公司</div>
          <el-select v-model="business_company" placeholder="请选择" clearable @clear="handleEmptybusiness">
            <el-option v-for="item in companis" :key="item.value" :label="item.name" :value="item.value" @click.native="businesschenge(item)"></el-option>
          </el-select>
        </div>

        <div class="tis">
          <div class="titles">维修类型</div>
          <el-select v-model="maintain_type" placeholder="请选择" clearable @clear="handleEmptytype">
            <el-option v-for="item in types" :key="item.value" :label="item.name" :value="item.value" @click.native="typechenge(item)"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lilst">
        <div class="tis">
          <div class="titles">客户名称</div>
          <el-input style="width: 300px" placeholder="请输入客户名称" v-model="ticketname" class="input-with-select" clearable @clear="handleEmptyticketname">
            <el-button slot="append" icon="el-icon-search" @click="ticketnamefun()"></el-button>
          </el-input>
        </div>

        <div class="tis">
          <div class="titles">地址筛选</div>
          <!-- <el-input style="width: 300px" placeholder="请输入地址" v-model="address" class="input-with-select" clearable @clear="handleEmptyaddress">
            <el-button slot="append" icon="el-icon-search" @click="addressfun()"></el-button>
          </el-input> -->
          <el-cascader ref="tree" @change="optpidfun" v-model="address" :options="usetypelist" clearable :props="casprops" @clear="handleEmptyaddress"></el-cascader>
        </div>

        <div class="tiss">
          <div class="titles">服务工程师</div>
          <div>
            <!-- <el-select v-model="maintain_user" placeholder="未选择" @clear="handleEmptyuser" clearable>
              <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value" @click.native="choicedrug(item)"></el-option>
            </el-select> -->
            <el-autocomplete
              clearable
              @clear="handleEmptyuser"
              :trigger-on-focus="false"
              placeholder="请输入工程师名称"
              v-model="gongchengs"
              :fetch-suggestions="shenvalidateCounts"
              value-key="name"
              @select="shenhandleSelect($event, '申请人')"
            ></el-autocomplete>
          </div>
        </div>
      </div>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="ticket_order_no" label="订单编号" width="180"></el-table-column>
        <el-table-column prop="business_company_name" label="业务公司" width="180"></el-table-column>
        <el-table-column prop="customer_name" label="客户名称"></el-table-column>
        <el-table-column prop="contact_address" label="客户地址" align="center"></el-table-column>
        <el-table-column prop="maintain_user" label="服务工程师" align="center"></el-table-column>
        <el-table-column prop="maintain_type_desc" label="维修类型"></el-table-column>
        <!-- <el-table-column prop="address" label="订单状态"></el-table-column> -->
        <el-table-column prop="created_time" label="起始时间"></el-table-column>
        <!-- <el-table-column prop="address" label="结束时间"></el-table-column> -->
        <el-table-column label="操作" width="180px" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-view" @click="detail(scope.row.order_id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!-- 订单详情 -->
    <el-dialog title="售后订单详情" :visible.sync="dialogFormVisible" center width="55%">
      <el-form :model="orderlist">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="8">
            <el-form-item label="订单编号" label-width="90px">
              <div class="el-select"><el-input v-model="orderlist.ticket_order_no" disabled></el-input></div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="业务公司" label-width="90px">
              <div class="el-select"><el-input v-model="orderlist.business_company_name" disabled></el-input></div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="8">
            <el-form-item label="工作日期" label-width="90px">
              <div class="el-select"><el-input v-model="orderlist.work_date" disabled></el-input></div>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="客户联系人" label-width="90px">
              <div class="el-select"><el-input v-model="orderlist.customer_linkman" disabled></el-input></div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="8">
            <el-form-item label="客户名称" label-width="90px">
              <div class="el-select"><el-input v-model="orderlist.customer_name" disabled></el-input></div>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="联系方式" label-width="90px">
              <div class="el-select"><el-input v-model="orderlist.customer_contact" disabled></el-input></div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="8">
            <el-form-item label="维修地点" label-width="90px">
              <div class="el-select"><el-input v-model="orderlist.maintain_address" disabled></el-input></div>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="维修类型" label-width="90px">
              <el-radio v-model="orderlist.maintain_type" label="contract" disabled>合同维修</el-radio>
              <el-radio v-model="orderlist.maintain_type" label="temp" disabled>临时维修</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around" v-if="orderlist.maintain_type == 'contract'">
          <el-col :span="8">
            <el-form-item label="所属合同" label-width="90px">
              <div class="el-select"><el-input v-model="orderlist.contract_name" disabled></el-input></div>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="合同编号" label-width="90px">
              <div class="el-select"><el-input v-model="orderlist.contract_no" disabled></el-input></div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="hint">用料审批单</div>
      <el-table :data="matter_items" border style="width: 85%; margin-left: 10%">
        <el-table-column prop="matter_order_no" label="审批单编号" width="220" align="center"></el-table-column>
        <el-table-column prop="user_name" label="申请人" width="150" align="center"></el-table-column>
        <el-table-column prop="matter_type_desc" label="提报事项" align="center"></el-table-column>
        <el-table-column label="费用(元)" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.amount / 100 }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100px" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="ingredient(scope.row.id, scope.row.matter_type)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="tong">已完成成本总计{{ orderlist.matter_amount / 100 }}元</div>
      <div class="hint">维修工单</div>
      <el-table :data="work_items" border style="width: 85%; margin-left: 10%">
        <el-table-column prop="work_order_no" label="工单编号" width="220" align="center"></el-table-column>
        <el-table-column prop="user_name" label="服务工程师" width="150" align="center"></el-table-column>
        <el-table-column prop="fault_desc" label="提报事项" align="center"></el-table-column>
        <el-table-column label="工单报价(元)" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.amount / 100 }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100px" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="maintain(scope.row.id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="tong">已完成营收总计{{ orderlist.work_amount / 100 }}元</div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogFormVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogFormVisible = false">关 闭</el-button>
      </div>

      <!-- 维修工单详情 -->
      <el-dialog width="50%" title="售后工单完成详情" :visible.sync="innerVisible" append-to-body center>
        <el-form :model="worklist">
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="10">
              <el-form-item label="服务工程师" label-width="125px">
                <div class="el-select"><el-input v-model="worklist.order_user_name" disabled></el-input></div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="维修时间" label-width="125px">
                <div class="el-select"><el-input v-model="worklist.create_time" disabled></el-input></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="10">
              <el-form-item label="维修设备名称" label-width="125px">
                <div class="el-select"><el-input v-model="worklist.device_name" disabled></el-input></div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="维修设备生产日期" label-width="125px">
                <div class="el-select"><el-input v-model="worklist.device_date" disabled></el-input></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="10">
              <el-form-item label="故障描述" label-width="125px">
                <div class="el-select"><el-input type="textarea" :rows="2" v-model="worklist.fault_desc" disabled></el-input></div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="维修方案" label-width="125px">
                <div class="el-select"><el-input type="textarea" :rows="2" v-model="worklist.maintain_scheme" disabled></el-input></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="10">
              <el-form-item label="故障照片" label-width="125px">
                <el-image
                  :z-index="5000"
                  style="width: 100px; height: 100px"
                  v-for="(item, index) in worklist.fault_images"
                  :key="index"
                  :src="item"
                  :preview-src-list="worklist.fault_images"
                ></el-image>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="完成图片" label-width="125px">
                <el-image
                  :z-index="5000"
                  style="width: 100px; height: 100px"
                  v-for="(item, index) in worklist.maintain_images"
                  :key="index"
                  :src="item"
                  :preview-src-list="worklist.maintain_images"
                ></el-image>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="hint">货物消耗</div>
        <el-table :data="worklist.goods_items" border style="width: 85%; margin-left: 10%">
          <el-table-column prop="goods_no" label="编号" width="130" align="center"></el-table-column>
          <el-table-column prop="goods_name" label="货物名称" width="200" align="center"></el-table-column>
          <el-table-column prop="goods_desc" label="货物详情" align="center" width=""></el-table-column>
          <el-table-column label="单价(元)" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.goods_price / 100 }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="goods_num" label="数量" align="center"></el-table-column>
          <el-table-column label="小计(元)" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.amount / 100 }}</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="tongs" style="hight: 30px">总计{{ worklist.amount / 100 }}元</div>
        <div slot="footer" class="dialog-footer">
          <!-- <el-button @click="innerVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="innerVisible = false">关 闭</el-button>
        </div>
      </el-dialog>

      <!-- 出库详情 -->
      <el-dialog title="出库详情" :visible.sync="outputVisible" append-to-body center>
        <el-form :model="outputlist">
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="出库仓库" label-width="90px">
                <div class="el-select"><el-input v-model="outputlist.house_name" disabled></el-input></div>
              </el-form-item>
            </el-col>

            <el-col :span="8"></el-col>
          </el-row>
        </el-form>
        <el-table :data="outputlist.items" border style="width: 85%; margin-left: 10%">
          <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
          <el-table-column prop="goods_no" label="货物编号" width="170" align="center"></el-table-column>
          <el-table-column prop="goods_name" label="货物名称" width="170" align="center"></el-table-column>
          <el-table-column prop="goods_desc" label="货物详情" align="center" width="170"></el-table-column>
          <el-table-column prop="goods_num" label="货物数量" align="center" width="100"></el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <!-- <el-button @click="outputVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="outputVisible = false">关 闭</el-button>
        </div>
      </el-dialog>

      <!-- 自行采购详情 -->
      <el-dialog title="自行采购详情" :visible.sync="buyVisible" append-to-body center>
        <el-table :data="buylist.items" border style="width: 85%; margin-left: 10%">
          <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
          <el-table-column prop="goods_no" label="货物编号" width="170" align="center"></el-table-column>
          <el-table-column prop="goods_name" label="货物名称" width="170" align="center"></el-table-column>
          <el-table-column prop="goods_desc" label="货物详情" align="center" width="170"></el-table-column>
          <el-table-column prop="goods_num" label="货物数量" align="center" width="100"></el-table-column>
          <el-table-column label="单项小计" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.goods_amount / 100 }}</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="tongs">费用总计{{ buylist.amount / 100 }}元</div>
        <el-form :model="buylist" class="buyfrom">
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="10">
              <el-form-item label="支付截图" label-width="125px">
                <el-image
                  :z-index="5000"
                  style="width: 100px; height: 100px"
                  v-for="(item, index) in buylist.pay_images"
                  :key="index"
                  :src="item"
                  :preview-src-list="buylist.pay_images"
                ></el-image>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="收据截图" label-width="125px">
                <el-image
                  :z-index="5000"
                  style="width: 100px; height: 100px"
                  v-for="(item, index) in buylist.receipt_images"
                  :key="index"
                  :src="item"
                  :preview-src-list="buylist.receipt_images"
                ></el-image>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="是否有发票" label-width="90px">
                <el-radio v-model="buylist.buy_bill" :label="1" disabled>有</el-radio>
                <el-radio v-model="buylist.buy_bill" :label="2" disabled>无</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <!-- <el-button @click="buyVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="buyVisible = false">关 闭</el-button>
        </div>
      </el-dialog>

      <!-- 外协详情 -->
      <el-dialog title="外协详情" :visible.sync="helpVisible" append-to-body center width="40%">
        <el-form :model="helplist">
          <el-row>
            <el-col :span="23">
              <el-form-item label="外协事务名称" label-width="125px">
                <div class="el-select"><el-input v-model="helplist.help_name" disabled></el-input></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="23">
              <el-form-item label="事务详情" label-width="125px">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="helplist.help_detail"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="23">
              <el-form-item label="外协费用" label-width="125px">
                <div class="el-select"><el-input v-model="helpamount" disabled></el-input></div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="23">
              <el-form-item label="支付截图" label-width="125px">
                <el-image
                  :z-index="5000"
                  style="width: 100px; height: 100px"
                  v-for="(item, index) in helplist.pay_images"
                  :key="index"
                  :src="item"
                  :preview-src-list="helplist.pay_images"
                ></el-image>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="23">
              <el-form-item label="收据截图" label-width="125px">
                <el-image
                  :z-index="5000"
                  style="width: 100px; height: 100px"
                  v-for="(item, index) in helplist.receipt_images"
                  :key="index"
                  :src="item"
                  :preview-src-list="helplist.receipt_images"
                ></el-image>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="是否有发票" label-width="90px">
                <el-radio v-model="helplist.buy_bill" :label="1" disabled>有</el-radio>
                <el-radio v-model="helplist.buy_bill" :label="2" disabled>无</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <!-- <el-button @click="helpVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="helpVisible = false">关 闭</el-button>
        </div>
      </el-dialog>

      <!-- 业务费用详情 -->
      <el-dialog title="业务费用详情" :visible.sync="businessVisible" append-to-body center width="40%">
        <el-form :model="businesslist">
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="业务费用" label-width="90px">
                <div class="el-select"><el-input v-model="businesslistamount" disabled></el-input></div>
              </el-form-item>
            </el-col>

            <el-col :span="8"></el-col>
          </el-row>

          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="费用详情" label-width="90px">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="businesslist.business_detail"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8"></el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <!-- <el-button @click="businessVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="businessVisible = false">关 闭</el-button>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { Drop } from '../../../api/slogong'
import { Area } from '../../../api/pact.js'
import Fenye from '../../../components/Fenye'
import { Getren } from '../../../api/shouhuo.js'
import { Orderlist, Orderdetail, Workdetail, Orderinfo, Options } from '../../../api/afterOrder.js'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      gongchengs: '',
      caigoulist: [],
      userid: '',
      renlist: [],
      address: '', //客户地址
      addresscode: '',
      usetypelist: [],
      company: '',
      companys: '',
      maintain_type: '', // 类型
      from_time: '', // 开始日期
      end_time: '', // 结束日期
      timeFrom: [], // 日期数组
      ticketname: '', // 客户名称
      ticketno: '', // 订单编号
      outputVisible: false, // 出库
      buyVisible: false, // 自行采购
      helpVisible: false, // 外协
      businessVisible: false, // 业务
      companis: [], // 业务公司
      buylist: {},
      helplist: {},
      helpamount: '',
      businesslist: {},
      outputlist: {},
      businesslistamount: '',
      maintain_user: '',
      innerVisible: false, // 维修工单详情弹窗
      dialogFormVisible: false, // 订单弹窗
      business_company: '',
      maintain_type: '',
      tableData: [],
      matter_items: [],
      work_items: [],
      orderlist: {}, // 订单详情数据
      worklist: {}, // 工单详情数据
      pageData: {
        page: 1,
        size: 10,
        count: 0
      },
      casprops: { value: 'code', label: 'area_name', checkStrictly: false, children: 'list' }, // 地区级联选择的配置

      types: [
        { name: '合同维修', value: 'contract' },
        { name: '临时维修', value: 'temp' }
      ],
      other: { other: ['work_item', 'matter_item'] }
    }
  },
  mounted() {
    this.Orderlists()
    this.Optionss()
    this.Getrenlist()
    this.Areas()
  },
  methods: {
    // 选中工程师
    shenhandleSelect(e) {
      this.maintain_user = e.value
      this.Orderlists()
    },

    // 搜索工程师后的数据
    shenvalidateCounts(queryString, cb) {
      // 调用搜索方法
      this.Crops(queryString, cb)
    },

    optpidfun(e) {
      this.addresscode = e[e.length - 1] === undefined ? 0 : e[e.length - 1]
      this.Orderlists()
    },

    // 维修工单
    maintain(id) {
      // console.log(id)
      this.Workdetails(id)
      this.innerVisible = true
    },

    // 用料单
    ingredient(id, matter_type) {
      if (matter_type === 'output') {
        this.outputVisible = true
        this.Orderinfos(id)
      } else if (matter_type === 'buy') {
        this.buyVisible = true
        this.Orderinfos(id)
      } else if (matter_type === 'help') {
        this.helpVisible = true
        this.Orderinfos(id)
      } else if (matter_type === 'business') {
        this.businessVisible = true
        this.Orderinfos(id)
      }
    },

    // 删除
    handleEmpty() {
      this.ticketno = ''
      this.companys = ''
      this.Orderlists()
    },

    handleEmptyticketno() {
      this.ticketno = ''
      this.Orderlists()
    },

    handleEmptyticketname() {
      this.ticketname = ''
      this.Orderlists()
    },

    handleEmptyaddress() {
      this.address = ''
      this.addresscode = ''
      this.Orderlists()
    },

    handleEmptybusiness() {
      this.business_company = ''
      this.Orderlists()
    },

    handleEmptytype() {
      this.maintain_type = ''
      this.Orderlists()
    },

    handleEmptyuser() {
      this.gongchengs = ''
      this.maintain_user = ''
      this.Orderlists()
    },

    // 类型
    typechenge(e) {
      // console.log(e)
      this.maintain_type = e.value
      this.Orderlists()
    },

    // 订单编号筛选
    ticketnofun() {
      // console.log(this.ticketno)
      if (this.ticketno === '') {
        this.$message({
          message: '请输入订单编号',
          type: 'warning'
        })
      } else {
        this.Orderlists()
      }
    },

    // 客户名称筛选
    ticketnamefun() {
      // console.log(this.ticketname)
      if (this.ticketname === '') {
        this.$message({
          message: '请输入客户名称',
          type: 'warning'
        })
      } else {
        this.Orderlists()
      }
    },

    addressfun() {
      this.Orderlists()
    },

    // 业务公司
    businesschenge(e) {
      // console.log(e)
      // this.companys = e.value
      this.Orderlists()
    },

    // 维修工程师
    choicedrug() {
      this.Orderlists()
    },

    // 日期选中事件
    gettime(timeFrom) {
      // console.log(timeFrom)
      if (timeFrom !== null) {
        this.from_time = timeFrom ? timeFrom[0] : ''
        this.end_time = timeFrom ? timeFrom[1] : ''
        this.Orderlists()
      } else {
        this.from_time = ''
        this.end_time = ''
        this.Orderlists()
      }
    },

    // 订单详情
    detail(order_id) {
      this.Orderdetails(order_id)
      this.dialogFormVisible = true
    },

    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Orderlists()
    },

    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Orderlists()
    },

    // 新售后订单列表
    async Orderlists() {
      const { data } = await Orderlist({
        ...this.pageData,
        ticket_order_no: this.ticketno,
        customer_name: this.ticketname,
        business_company: this.business_company,
        from_time: this.from_time,
        end_time: this.end_time,
        maintain_type: this.maintain_type,
        address: this.addresscode,
        maintain_user: this.maintain_user
      })
      // console.log(data)
      this.tableData = data.data.data
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 新售后订单详情
    async Orderdetails(order_id) {
      const { data } = await Orderdetail({ order_id: order_id, ...this.other })
      // console.log(data)
      this.orderlist = data.data
      this.work_items = data.data.work_items
      this.matter_items = data.data.matter_items
    },

    // 新售后工单详情
    async Workdetails(order_id) {
      const { data } = await Workdetail({ order_id: order_id })
      // console.log(data)
      this.worklist = data.data
    },

    async Orderinfos(order_id) {
      const { data } = await Orderinfo({ order_id: order_id })
      // console.log(data)
      if (data.data.matter_type === 'output') {
        this.outputlist = data.data
      } else if (data.data.matter_type === 'buy') {
        this.buylist = data.data
      } else if (data.data.matter_type === 'help') {
        this.helplist = data.data
        this.helpamount = data.data.amount / 100
      } else if (data.data.matter_type === 'business') {
        this.businesslist = data.data
        this.businesslistamount = data.data.amount / 100
      }
    },

    // 业务公司
    async Optionss() {
      const { data } = await Options()
      // console.log(data)
      this.companis = data.data.companis
    },

    // 工程师
    async Getrenlist() {
      const { data } = await Getren()
      this.renlist = data.data
    },

    // 地区联动
    async Areas() {
      const { data } = await Area()
      this.usetypelist = data.data.list
    },

    // 工程师
    async Crops(name, cd) {
      const { data } = await Drop({ name })
      // console.log(data.data)
      this.caigoulist = data.data
      cd(data.data)
    }
  }
}
</script>

<style scoped >
.el-form-item__content:hover {
  cursor: crosshair !important;
}
.hint {
  width: 100%;
  font-size: 16px;
  color: red;
  box-sizing: border-box;
  padding-left: 10%;
  font-weight: bold;
}
.tong {
  width: 85%;
  border: 1px solid #ebeef5;
  margin-left: 10%;
  box-sizing: border-box;
  padding-left: 2%;
}
.tongs {
  width: 85%;
  height: 50px;
  line-height: 50px;
  border: 1px solid #ebeef5;
  margin-left: 10%;
  box-sizing: border-box;
  padding-left: 2%;
}
.buyfrom {
  margin-top: 30px !important;
}
.lilst {
  display: flex;
}
.tis {
  margin-right: 15px;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.tiss {
  width: 25%;
  font-size: 13px;
  margin-left: 3%;
  display: flex;
  align-items: center;
}
.titles {
  margin-right: 5px;
}
</style>